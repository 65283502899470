import React, { useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/scrollbar';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import DesktopWidgetItem from './desktopWidgetItem';
import { Keyboard, Scrollbar} from 'swiper/modules';

export default function DesktopWidget(props) {
    const navbarWidgetDataDesktopInfo = props.navbarWidgetDataDesktop;
    const showArrows = navbarWidgetDataDesktopInfo.length > 5;
    let itemsToShow;

    if (window.innerWidth > 1199 && showArrows) {
        itemsToShow = 7;
    } else if (window.innerWidth > 1024 && window.innerWidth < 1200 && showArrows) {
        itemsToShow = 6;
    } else {
        itemsToShow = 5;
    }

    const [showIndicator, setShowIndicator] = useState(navbarWidgetDataDesktopInfo.length > 7);
    const [showLeftIndicator, setShowLeftIndicator] = useState(false);
    const [showScrollbar, setShowScrollbar] = useState(false);

    const showSlider = navbarWidgetDataDesktopInfo.length > 7
    const addContainerClass = window.innerWidth > 1199 
    const scrollbar = showScrollbar && showSlider

    return (
        <div className='pos-relative'>
            <div
                className={`text-center text-bold info-delivery padd-top-5 padd-bottom-5 ${addContainerClass ? 'container' : ''}` }
                onMouseEnter={() => setShowScrollbar(true)}
                onMouseLeave={() => setShowScrollbar(false)}
            >
                {showSlider ? (
                    <Swiper
                        slidesPerView={itemsToShow}
                        centeredSlides={false}
                        slidesPerGroupSkip={1}
                        grabCursor={showSlider}
                        keyboard={{
                            enabled: true,
                        }}
                        scrollbar={scrollbar}
                        modules={[Keyboard, Scrollbar]}
                        className="mySwiper"
                        onSlideChange={(swiper) => {
                            const currentSlide = swiper.activeIndex;
                            const isFirstSlide = currentSlide === 0;
                            setShowLeftIndicator(!isFirstSlide);
    
                            if (currentSlide + itemsToShow >= navbarWidgetDataDesktopInfo.length) {
                                setShowIndicator(false);
                            } else {
                                setShowIndicator(true);
                            }
                        }}
                    >
                        {navbarWidgetDataDesktopInfo.map((item, index) => (
                            <SwiperSlide key={index}>
                                <DesktopWidgetItem widgetItemInfo={item} key={item.id} positionNumber={item.id} />
                            </SwiperSlide>
                        ))}
                    </Swiper>
                ) : (
                    <section className="header_panel_options">
                        {navbarWidgetDataDesktopInfo.map((item) => (
                            <DesktopWidgetItem widgetItemInfo={item} key={item.id} positionNumber={item.id} />
                        ))}
                    </section>
                )}
            </div>
            {showIndicator && (
                <div className="indicator pos-absolute"></div>
            )}
            {showLeftIndicator && (
                <div className="indicator__left pos-absolute"></div>
            )}
        </div>
    );
    
}
