import React, { Fragment } from 'react'
import { getEventByTggEventLable } from '../dataLayerData';
import useWindowSize from './../hooks/useWindowSize';


function TabletWidgetItem(props) {
    const screenStatus = useWindowSize()
    let itemImgUrl = props.widgetItemInfo.Link_Image
    let itemImgAlt = props.widgetItemInfo.Link_Image_Alt
    let itemImgWidth = props.widgetItemInfo.Link_Image_Width
    let itemImgHeight = props.widgetItemInfo.Link_Image_Height
    let itemLink = props.widgetItemInfo.Link_Redirect
    let itemName = props.widgetItemInfo.Link_Name
    let itemNameSuffix = props.widgetItemInfo.Link_Name_Suffix
    let positionNumber = props.positionNumber
    return (
        <Fragment>
            <div className="panel_options_icons_text">
                <img className="img-responsive disp-inline marg-right-5 pos-relative" alt={itemImgAlt} src={itemImgUrl} style={{top: "-2px"}} width={itemImgWidth} height={itemImgHeight}/>
                <a href={itemLink}  onClick={() => getEventByTggEventLable(`"${screenStatus} Navigation Bar Click"`, `"${itemName}" Position ${positionNumber}`)}>
                    <span className="disp-inline padd-top-5 text-medium">{itemName} </span>
                    <span style={{ textDecoration: 'underline' }} onClick={() => getEventByTggEventLable(`"${screenStatus} Navigation Bar Click"`, `"${itemName}" Position ${positionNumber}`)}>{itemNameSuffix}</span>
                </a>
            </div>
        </Fragment>
    )
}

export default TabletWidgetItem
