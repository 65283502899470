import React, { Fragment, useState, useEffect } from 'react'
import './App.css'
import _ from 'lodash'
import useHttp from './hooks/useHttp'
import useWindowSize from './hooks/useWindowSize'
import DesktopWidget from './components/desktopWidgetWrapper'
import TabletWidget from './components/tabletWidgetWrapper'
import MobileWidget from './components/mobileWidgetWrapper'

function App() {
  const [navbarWidgetDataDesktop, setNavbarWidgetDataDesktop] = useState();
  const [navbarWidgetDataTablet, setNavbarWidgetDataTablet] = useState();
  const [navbarWidgetDataMobile, setNavbarWidgetDataMobile] = useState();
  const screenStatus = useWindowSize()
  const { isLoading, error, sendRequest: fetchTasks } = useHttp();

 
  let firbaseUrl = null
  firbaseUrl = `https://navbar-widget-f0a36-default-rtdb.firebaseio.com/data.json` //prod links
  //firbaseUrl = `https://navbar-widget.firebaseio.com/data.json` //tst links

  useEffect(() => {
    const transformNavbarWidgetData = (Obj) => {
      const navbarWidgerDataArr = _.without(Obj, undefined, null, "");
      let desktopInfo = []
      let tabletInfo = []
      let mobileInfo = []
      _.map(navbarWidgerDataArr, function (o, index) {
        if (o.Screen_Identifier === "desktop") {
          desktopInfo.push({ ...o, key: index }); // Add key property
        } else if (o.Screen_Identifier === "tablet") {
          tabletInfo.push({ ...o, key: index }); // Add key property
        } else if (o.Screen_Identifier === "mobile") {
          mobileInfo.push({ ...o, key: index }); // Add key property
        } else {
          //console.log('NAVBAR WIDGET HAS NOT VALID DATA')
          setNavbarWidgetDataDesktop()
          setNavbarWidgetDataTablet()
          setNavbarWidgetDataMobile()
        }
      });
      setNavbarWidgetDataDesktop(desktopInfo);
      setNavbarWidgetDataTablet(tabletInfo);
      setNavbarWidgetDataMobile(mobileInfo);
    };
  
    fetchTasks({ url: firbaseUrl }, transformNavbarWidgetData);
  }, [fetchTasks]);


  return (
    <Fragment>
      {!error ?
        <div style={{ background: "#E0E0E0",zIndex:999 }}>
          {screenStatus === "Desktop" && navbarWidgetDataDesktop ? <DesktopWidget navbarWidgetDataDesktop={navbarWidgetDataDesktop} /> : null}
          {screenStatus === "Tablet" && navbarWidgetDataTablet ? <TabletWidget navbarWidgetDataTablet={navbarWidgetDataTablet} /> : null}
          {screenStatus === "Mobile" && navbarWidgetDataMobile ? <MobileWidget navbarWidgetDataMobile={navbarWidgetDataMobile} /> : null}
        </div> : null
      }
    </Fragment>
  );
}

export default React.memo(App);
