import React, { Fragment } from 'react'
import { getEventByTggEventLable } from '../dataLayerData';
import useWindowSize from './../hooks/useWindowSize';

function MobileWidgetItem(props) {
    const screenStatus = useWindowSize()
    let itemImgUrl = props.widgetItemInfo.Link_Image
    let itemImgAlt = props.widgetItemInfo.Link_Image_Alt
    let itemImgWidth = props.widgetItemInfo.Link_Image_Width
    let itemImgHeight = props.widgetItemInfo.Link_Image_Height
    let itemLink = props.widgetItemInfo.Link_Redirect
    let itemName = props.widgetItemInfo.Link_Name
    let itemNameSuffix = props.widgetItemInfo.Link_Name_Suffix
    let positionNumber = props.positionNumber - 5
    return (
        <Fragment>
            <div className="rmt-banner__slide">
                <a href={itemLink} target="_blank" rel="noreferrer" onClick={() => getEventByTggEventLable(`"${screenStatus} Navigation Bar Click"`, `"${itemName}" Position ${positionNumber}`)}>
                <img className="img-responsive disp-inline marg-right-5 pos-relative" alt={itemImgAlt} src={itemImgUrl} style={{top: "-2px"}} width={itemImgWidth} height={itemImgHeight}/>
                    {itemNameSuffix ? <span className="text-graydark-5">{itemName} </span> : <span>{itemName} </span>}
                    {itemNameSuffix ? <span style={{ textDecoration: 'underline' }}>{itemNameSuffix}</span> : null}
                </a>
            </div>
        </Fragment>
    )
}

export default MobileWidgetItem