import React, { Fragment } from 'react'
import TabletWidgetItem from './tabletWidgetItem'

function TabletWidget(props) {
    const navbarWidgetDataTabletInfo = props.navbarWidgetDataTablet
    //console.log(navbarWidgetDataTabletInfo,navbarWidgetDataTabletInfo)
    return (
        <Fragment>
                <div className="info-delivery fullwidth padd-top-5 padd-bottom-5">
                    <div className="container padd-top-5 padd-xs-0 padd-left-0 padd-right-0">
                        <section className="header_panel_options">
                            {
                                navbarWidgetDataTabletInfo && navbarWidgetDataTabletInfo.slice(0, 5).map((item) => {
                                    return <TabletWidgetItem widgetItemInfo={item} key={item.id} positionNumber ={item.id}/>
                                })
                            }
                        </section>
                    </div>
                </div>
        </Fragment>
    )
}

export default React.memo(TabletWidget)
