import React, { Fragment } from 'react'
import MobileWidgetItem from './mobileWidgetItem'
import Slider from "react-slick";


function MobileWidget(props) {
    const navbarWidgetDataMobileInfo = props.navbarWidgetDataMobile
    //console.log('navbarWidgetDataMobileInfo',navbarWidgetDataMobileInfo)
    var settings = {
        lazyLoad: "ondemand",
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: !1,
        dots: !1,
        fade: !0,
        infinite: !0,
        autoplay: !0,
        autoplaySpeed: 2500,
        speed: 1e3,
        className: "navbarWidgetMobWrapper",
        pauseOnFocus: false,
        pauseOnHover: false,
        //slidesToShow: 5,
        //focusOnSelect: false,
        //adaptiveHeight: true,
        //variableWidth: true
        // draggable:true,
        //centerMode: true,
        //initialSlide: 5,
    };
    //console.log('prop', navbarWidgetDataMobileInfo)
    return (
        <Fragment>
            <div className="pos-relative text-center text-bold slider">
                <Slider {...settings}>
                    {
                        navbarWidgetDataMobileInfo && navbarWidgetDataMobileInfo.map((item) => {
                            return <MobileWidgetItem widgetItemInfo={item} key={item.id} positionNumber ={item.id}/>
                        })
                    }

                </Slider>
            </div>
        </Fragment >
    )
}

export default React.memo(MobileWidget)
